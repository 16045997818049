// Shortcircuit variable
let triggered = false;

function ScrollExecute() {
  // Locate loadmore button
  let moreButon = $('#more').last();
  
  // Get URL from the loadmore button
  let nextUrl = $(moreButon).find('a').attr("data-next");
  
  // Button position when AJAX call should be made one time
  //if ((($(moreButon).offset().top - $(window).scrollTop()) < 800) && (triggered == false)) {
  
    // Trigger shortcircuit to ensure AJAX only fires once
    triggered = true;

    // Make ajax call to next page for load more data
    $.ajax({
      url: nextUrl,
      type: 'GET',
      beforeSend: function() {
       // moreButon.remove();
      }
    })
    .done(function(data) {
      // Append data
      $('.list-pro').append($(data).find('.list-pro').html());
      $('.load_more_btn').attr('data-next',$(data).find('.load_more_btn').attr('data-next'));
      
      if(!$('.load_more_btn').attr('data-next'))
      {
        $('.load_more_btn').hide();
      }

      // On success, reset shortcircuit
      triggered = false
    });
 // }
}

$(document).ready(function () {
   
  $('.load_more_btn').click(function(){
    
   // alert('hello');
     ScrollExecute();
     
  });
});
